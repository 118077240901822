import { tableHeadFixer } from './tableHeadFixer';

function setTooltipsForSubjectAccessors() {
  $('.subject_accessor').each(function () {
    const tooltip = $(this).data('tooltip');
    $(this).tooltip({ content: tooltip });
  });
}

function openSubjectAccessorsPopup(userId, closeCallback) {
  $.ajax({
    url: '/permission_rules/subject_accessors.js',
    type: 'GET',
    data: { user_id: userId },
    success() {
      $('#subject-accessors-table').tableHeadFixer({ left: 1, head: true });
      $('.scrollable-table').css('overflow-y', 'scroll');
      setTooltipsForSubjectAccessors();
      $('#subject_accessors_popup').dialog({
        modal: true,
        title: 'User Access Rules',
        width: 'auto',
        autoOpen: true,
        resizable: false,
        autoSize: true,
        close() {
          if (closeCallback) { closeCallback(); }
          $(this).dialog('destroy');
        },
      });
      $('.chosen-select').chosen({ width: 'auto' });
    },
  });
}

$(document).ready(() => {
  function fetchSubjectIdsFromTableHeader() {
    const subjectIds = [];
    $('#subject-accessors-table th').each(function () {
      const subjectId = $(this).data('id');
      if (subjectId) {
        subjectIds.push(subjectId);
      }
    });
    return subjectIds;
  }

  function reloadPermissionRulesSection(userId) {
    $.ajax({
      url: `/users/${userId}/permission_rules`,
      type: 'GET',
      dataType: 'html',
      success(data) {
        $('#permission_rules_section').html(data);
      },
    });
  }

  function updateSubjectAccessor(subjectAccessor) {
    const currState = subjectAccessor.prop('checked');
    if (subjectAccessor.hasClass('disabled_checkbox')) {
      subjectAccessor.prop('checked', !currState);
      return;
    }
    const userId = $('#subject-accessors-content #user_id').data('userId');
    const permissionId = subjectAccessor.parents('tr').data('permissionId');
    const dataNode = subjectAccessor.parents('td');
    const subjectId = dataNode.data('subjectId');
    const subjectType = dataNode.data('subjectType');
    $.ajax({
      url: '/permission_rules/update_subject_accessor',
      type: 'POST',
      data: {
        user_id: userId,
        permission_id: permissionId,
        subject_id: subjectId,
        subject_type: subjectType,
        should_persist: currState,
        subject_ids: fetchSubjectIdsFromTableHeader(),
      },
      success() {
        $('.chosen-select').chosen({ width: 'auto' });
        setTooltipsForSubjectAccessors();
      },
    });
  }

  function updateAvailableSubjects() {
    const subjectIds = $('#available_subjects').val();
    if (subjectIds === undefined) { return; }

    const userId = $('#subject_accessors_popup #user_id').data('userId');
    $.ajax({
      url: '/permission_rules/update_available_subjects',
      type: 'POST',
      data: { subject_ids: subjectIds, user_id: userId },
      success() {
        $('.chosen-select').chosen({ width: 'auto' });
        $('.scrollable-table').css('overflow-y', 'scroll');
        setTooltipsForSubjectAccessors();
      },
    });
  }

  $(document).on('click', '#edit_user_form .subject_accessors_link', function () {
    event.preventDefault();
    const userId = $(this).data('userId');
    const closeCallback = function () {
      reloadPermissionRulesSection(userId);
    };
    openSubjectAccessorsPopup(userId, closeCallback);
  });

  $(document).on('click', 'table.user_permissions .subject_accessors_link', function () {
    event.preventDefault();
    const userId = $(this).parents('tr').data('userId');
    const closeCallback = () => location.reload();
    openSubjectAccessorsPopup(userId, closeCallback);
  });

  $(document).on('click', '.subject_accessor', function () {
    updateSubjectAccessor($(this));
  });

  $(document).on('touch', '.subject_accessor', function () {
    updateSubjectAccessor($(this));
  });

  $(document).on('change', '#available_subjects', () => {
    updateAvailableSubjects();
  });
});

export { openSubjectAccessorsPopup };
