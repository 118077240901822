import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
import MaterialTableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { BrowserRouter as Router, Link } from 'react-router-dom';

const useStyles = makeStyles(() => ({
  row: {
    cursor: 'pointer',
  },
  customCellHover: {
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
    },
  },
}));

const TableBody = ({
  rows, headCells, handleRowClick, customCellContentProvider, customClicksHandler, customLink, makeAjaxRequest, isAjax,
}) => {
  const classes = useStyles();

  const handleCellClick = (row, cell) => {
    if (cell.unclickable) { return; }

    if (cell.customClickHandler) {
      customClicksHandler(row, cell.key);
    } else if (handleRowClick.length !== 0) {
      handleRowClick(row);
    } else if (isAjax) {
      makeAjaxRequest(row);
    } else if (customLink.length !== 0) {
      window.location.href(customLink(row, cell.key));
    }
  };

  return (
    <Router>
      <MaterialTableBody>
        {rows.map((row) => (
          <TableRow
            className={classes.row}
            tabIndex={-1}
            key={row.id}
            hover
          >
            {headCells.map((cell) => (
              <TableCell
                key={cell.key}
                className={cell.customClickHandler ? classes.customCellHover : ''}
                onClick={() => handleCellClick(row, cell)}
                align="left"
                {...(customLink(row, cell.key) && {
                  component: Link,
                  to: customLink(row, cell.key),
                })}
              >
                {cell.customContent ? customCellContentProvider(row, cell.key) : row[cell.key]}
              </TableCell>
            ))}
          </TableRow>
        ))}
      </MaterialTableBody>
    </Router>
  );
};

TableBody.propTypes = {
  rows: PropTypes.arrayOf(PropTypes.shape).isRequired,
  headCells: PropTypes.arrayOf(PropTypes.shape).isRequired,
  handleRowClick: PropTypes.func,
  customCellContentProvider: PropTypes.func,
  customClicksHandler: PropTypes.func,
  customLink: PropTypes.func,
  makeAjaxRequest: PropTypes.func,
  isAjax: PropTypes.bool,
};

TableBody.defaultProps = {
  customCellContentProvider: null,
  isAjax: false,
  makeAjaxRequest: () => {},
  customClicksHandler: () => {},
  handleRowClick: () => {},
  customLink: () => {},
};

export default TableBody;
