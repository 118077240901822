import React, { useState } from 'react';
import TextField from '@mui/material/TextField';

export default function DecimalTextField(props) {
  const {
    name,
    id,
    existingValue,
    disabled = false,
  } = props;

  const [value, setValue] = useState(existingValue || '');

  return (
    <TextField
      style={{ width: '100%' }}
      id={id}
      name={name}
      value={value}
      disabled={disabled}
      onChange={(e) => {
        setValue(e.target.value);
      }}
    />
  );
}
