import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

export default function AutocompleteSelect(props) {
  const {
    id,
    options,
    currentlySelected,
    disabled = false,
  } = props;

  const shallowComparison = (a, b) => {
    if (typeof a !== 'object' || typeof b !== 'object') return (a === b);

    const keysA = Object.keys(a);
    const keysB = Object.keys(b);

    if (keysA.length !== keysB.length) {
      return false;
    }

    return keysA.every((key) => a[key] === b[key]);
  };

  let initialValue = '';

  options.forEach((rowToCompare) => {
    if (initialValue === '' || shallowComparison(rowToCompare.id, currentlySelected)) initialValue = rowToCompare;
  });

  const [selectedValue, setSelectedValue] = useState(initialValue);

  return (
    <>
      <Autocomplete
        disablePortal
        id={`${id}_control`}
        options={options}
        value={selectedValue}
        disabled={disabled}
        disableClearable
        onChange={(e, value) => { setSelectedValue(value); }}
        isOptionEqualToValue={(option, value) => shallowComparison(option.id, value.id)}
        renderInput={(params) => (
          <TextField
            {...params}
          />
        )}
      />
      <input type="hidden" id={id} name={id} value={JSON.stringify(selectedValue.id)} />
    </>
  );
}
