import React from 'react';

import AsyncTable from '../common/AsyncTable';
import {
  showSpinner,
  hideSpinner,
} from '../../src/helpers';
import '../../src/plans';

export default function PlannedPlantActivities() {
  const headCells = [
    { key: 'status', label: 'Status' },
    { key: 'project_name', label: 'Project' },
    { key: 'plant_name', label: 'Plant' },
    { key: 'start_time', label: 'Start' },
    { key: 'end_time', label: 'End' },
    { key: 'material_name', label: 'Material' },
    { key: 'from_location_name', label: 'From' },
    { key: 'to_location_name', label: 'To' },
    { key: 'quantity', label: 'Quantity' },
  ];

  const customLink = () => undefined;

  const makeAjaxRequest = (row) => {
    $.ajax({
      url: `/planned_plant_activities/${row.id}/edit.js`,
      beforeSend: showSpinner(),
      type: 'GET',
      success() {
        hideSpinner();
      },
    });
  };

  return (
    <AsyncTable
      url="planned_plant_activities"
      defaultOrderField="start_time"
      defaultOrder="desc"
      headCells={headCells}
      customLink={customLink}
      makeAjaxRequest={makeAjaxRequest}
      isAjax
    />
  );
}
